module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["zh","en","ja"],"defaultLanguage":"zh","siteUrl":"http://dev.mymoji.com.tw/","trailingSlash":"always","generateDefaultLanguagePage":true,"i18nextOptions":{"debug":false,"supportedLngs":["zh","en","ja"],"fallbackLng":"zh","detection":{"order":["path","querystring","localStorage","sessionStorage","navigator","cookie","subdomain","htmlTag"]},"interpolation":{"escapeValue":false},"nsSeparator":false},"pages":[{"matchPath":"/:lang?/404","getLanguageFromPath":false},{"matchPath":"/:lang?/twmsso","getLanguageFromPath":false}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"MyMoji","short_name":"MyMoji","start_url":"/","background_color":"#FFFFFF","theme_color":"#FFFFFF","display":"minimal-ui","icon":"src/images/logo_compressed.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4d4ce2cdf5c7f4dd18ae41748fc1e2cd"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-K6Q93FF","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
